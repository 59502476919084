import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"
import AboutPageTemplate from "../templates/AboutPageTemplate"

const AboutPage = ({ data }) => {
  const fm = data.directus.AboutPage

  return (
    <>
      <MyHelmet title={fm.title} description={fm.subHeading} />
      <AboutPageTemplate
        heading={fm.heading}
        subheading={fm.subHeading}
        html={fm.body}
      />
    </>
  )
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage {
    directus {
      AboutPage {
        id
        title
        heading
        subHeading
        body
      }
    }
  }
`
